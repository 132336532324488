.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 40px;
  height: 40px;
  background-color: grey;
}

#portrait-caption {
  scale: 70%;
}

.GalleryCarousel img {
  display: block;
  margin: 0 auto;
}

@media (max-width: 540px) {
  #carousel {
    margin-bottom: 0px;
  }

  #carousel h3 {
    font-size: 12px;
  }

  #carousel p {
    font-size: 8px;
  }
}

@media all and (min-device-width: 667px) and (max-device-width: 932px) and (orientation: landscape) {
  .GalleryCarousel #carousel {
    display: block;
    margin: 0 auto;
    max-width: 70%;
  }
}

@media screen {
  .carousel-caption {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    margin: 0 0 20px 0;
    padding: 5px 0 0 0;
  }
}

@media (min-width: 576px) {
  #landscape-caption {
    display: block;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  #landscape-caption h3 {
    font-size: 16px;
  }

  #landscape-caption p {
    font-size: 14px;
  }

  #portrait {
    display: block;
    margin: 0 auto;
    max-width: 45%;
  }
}

@media screen {
  #portrait {
    display: block;
    margin: 0 auto;
    width: 50%;
  }
}

@media screen and (max-width: 932px) and (orientation: landscape) {
  #portrait {
    display: block;
    margin: 0 auto;
    width: 40%;
  }
}

@media (min-width: 1024px) {
  .carousel-caption h3 {
    color: white;
    font-weight: 500;
    font-size: 20px !important;
    border-radius: 5px;
    font-size: 1.5rem;
  }

  .carousel-caption p {
    font-size: 14px !important;
  }
}
