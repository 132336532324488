.About .hero {
  background-image: linear-gradient(rgb(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/craft.jpg");
  background-repeat: no-repeat;
  background-position-y: 70%;
  background-size: cover;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.About p,
li {
  text-align: left;
}

.About #about-link {
  font-weight: bold;
}

.About h3 {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.About ul,
li {
  padding-left: 0;
}

#uses-container,
#amenities-container {
  background-color: rgba(0, 0, 0, 0.1);
  color: white !important;
  h3 {
    font-size: 20px;
  }
}

#venue-uses li {
  text-wrap: nowrap;
  font-size: 18px;
}

#venue-amenities p {
  text-align: left !important;
  text-wrap: wrap;
  font-size: 18px;
}

#banner-about > p {
  font-size: 28px !important;
}

.About .about-container {
  background-image: linear-gradient(rgb(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/sixth-and-main-venue-table-centerpiece2.jpeg");
  background-repeat: no-repeat;
  background-position-y: 25%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px !important;
}

.About .about-container p {
  font-size: 48px;
  text-align: center !important;
}

.About tbody > tr > th {
  font-weight: 300;
  font-size: 16px !important;
  text-align: left;
  margin-right: 0 !important;
}

/*mobile*/
@media (max-width: 696px) and (orientation: portrait) {
  .About .hero {
    height: 65vh;
  }

  .About h3 {
    font-size: 20px !important;
  }

  .About p {
    font-size: 16px;
    text-align: center !important;
  }

  .About .graduation p {
    font-size: 18px !important;
  }

  .about-btn-avail {
    font-size: 14px !important;
    letter-spacing: 1.5px;
    font-weight: 500;
  }

  #venue-amenities p,
  #venue-uses li {
    font-size: 14px;
    font-weight: 300;
    text-align: left !important;
  }

  .About .about-container {
    height: 300px !important;
  }

  .About .about-container p {
    font-size: 24px !important;
  }
}

@media (max-width: 932px) and (orientation: landscape) {
  .About .hero {
    background-position-y: 70%;
    background-size: cover;
    padding: 50px 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .About h3 {
    font-size: 20px !important;
  }

  .About p {
    font-size: 14px !important;
  }

  .About .graduation {
    padding: 0 40px;
  }

  .About .graduation h3 {
    font-size: 32px !important;
  }

  .About .graduation p {
    font-size: 18px !important;
  }

  #venue-amenities,
  #venue-uses {
    font-weight: 300;
  }

  #venue-amenities p,
  #venue-uses li {
    font-size: 14px;
    font-weight: 300;
  }

  .About .about-container {
    height: 300px !important;
  }
}
