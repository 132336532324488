@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  background: #e6e4d5;
  background: #212529;
}

div.Home,
div.About,
div.Gallery,
div.Contact,
div.Tour,
div.Reservation {
  background: #e6e4d5;
}

h1 {
  color: white;
  font-family: Raleway;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
}

p,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  font-family: Raleway;
  font-weight: 400;
  color: black;
}

p {
  font-size: 16px;
}

ul {
  list-style: none;
}

li {
  margin: 0 auto;
  padding: 4px 0;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: white;
}

.btn {
  background-color: rosybrown;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  display: block;
  margin: 0 auto;
  font-family: Raleway;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 1px;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 200ms ease;
}

.btn:hover {
  background-color: #dc143c; /* crimson */
  border: none;
  color: white;
  cursor: pointer;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.4);
}

.btn:active {
  background-color: crimson !important;
  cursor: pointer !important;
}

#availability-btn {
  background-color: #2b2c29;
}

#availability-btn:hover {
  background: crimson;
  border: none;
  color: white;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.4);
}

#learn-more-btn-bottom,
#book-now {
  font-size: 16px;
  text-align: center;
  width: 50%;
}

#regulations h2,
#regulations h6,
#regulations p {
  text-align: left !important;
}

/* Breakpoint: Small. Class infix: 'sm'. Dimensions: >=576px */
@media (min-width: 576px) {
  h1 {
    font-size: 36px;
    margin-bottom: 0 !important;
  }

  h2 {
    text-align: center;
  }

  h6 {
    font-size: 14px;
    text-align: center;
  }

  p {
    font-size: 18px;
  }
}

/* Breakpoint: Medium. Class infix: 'md'. Dimensions: >=768px */
@media (min-width: 768px) {
  h1 {
    font-size: 48px;
  }
}

/*large screens*/
@media (min-width: 1024px) {
  h1 {
    font-size: 56px;
    padding: 0 250px;
  }

  #learn-more-btn-bottom,
  #book-now {
    text-align: center;
    width: 35%;
  }
}
