.Contact .hero {
  background-image: linear-gradient(rgb(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/event-plan.jpg");
  background-repeat: no-repeat;
  background-position-y: 70%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Contact p {
  text-align: left;
}

.Contact .btn {
  width: 100%;
}

.Contact #content-wrap {
  display: flex;
  flex-direction: column;
}

.Contact #footer {
  margin-top: auto !important;
}

.Contact li {
  color: black;
}

.Contact #contact-link {
  font-weight: bold;
}

#contact-container {
  min-width: 90%;
}

#icon {
  color: rgb(88, 84, 84);
}

/* mobile browsers */
@media (max-width: 696px) and (orientation: portrait) {
  .Contact .hero {
    height: 65vh;
  }

  .Contact p {
    font-size: 16px;
  }

  .Contact p,
  .Contact li {
    min-height: auto;
    text-align: center !important;
  }

  .Contact .Footer p {
    text-align: center;
  }

  .Contact ul {
    padding-left: 0;
  }

  .Contact iframe {
    height: 300px;
  }

  .Contact .Footer h3 {
    margin-bottom: 3px !important;
  }

  .Contact .Footer p {
    font-size: 11px;
    margin-bottom: 20px;
  }
}

@media (max-width: 932px) and (orientation: landscape) {
  .Contact .hero {
    height: 100vh;
  }

  .Contact p,
  .Contact li {
    min-height: auto;
    text-align: left !important;
    font-size: 14px;
  }

  #contact-options {
    font-size: 10px !important;
  }

  .Contact .Footer h3 {
    font-size: 12px;
  }

  .Contact .Footer p {
    font-size: 10px;
  }
}

/* tablets */
@media all and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .Contact #content-wrap {
    height: 60vh;
  }

  .Contact #footer {
    height: 20vh;
  }
}

@media (min-device-width: 1024px) and (max-device-width: 1180px) and (orientation: landscape) {
  .Contact p {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  #contact-options {
    padding-top: 0 !important;
  }
}
