.Navbar,
.navContainer {
  font-family: Raleway;
  font-size: 14px;
  padding: 2px 30px;
  min-width: 100%;
}

div.navContainer.container {
  /*height: 75px;*/
  justify-content: space-between;
  margin: 0 auto;
}

.Navbar {
  display: flex;
}

#nav-brand-icon {
  filter: invert(1);
  scale: 125%;
}

#nav-brand-icon:hover {
  opacity: 80%;
}

.offcanvas {
  width: 100% !important;
}

.offcanvas-header {
  font-family: Raleway;
}

.offcanvas-header .offcanvas-title {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1px;
}

.offcanvas-header .btn-close {
  filter: invert(1);
}

.nav-link {
  color: white;
  font-family: Raleway;
  font-size: 14px;
  padding: 0;
}

.colorChange {
  background-color: #212529;
}

.nav-link:hover {
  color: white;
  opacity: 50% !important;
}

#toggle {
  border: 1px solid black;
  filter: invert(100%);
}

.colorChange .navbar-brand img {
  color: #fff !important;
  filter: invert(1) !important;
}

.colorChange #toggle {
  color: #333 !important;
  filter: invert(1) !important;
}
.dropdown-background {
  background: #2b2c29;
}

.dropdown-background .nav-link {
  color: white;
}

.dropdown-background .navbar-brand img {
  filter: invert(1);
}

.dropdown-background #toggle {
  filter: invert(1);
}

span.navbar-toggler-icon {
  width: 20px;
  height: 20px;
}

.Navigation #reservation-link {
  background-color: crimson;
  color: white;
  transition: 300ms ease-in-out;
}

@media (max-width: 576px) {
  .nav-link {
    color: white;
    font-family: Raleway;
    font-size: 36px;
    padding: 20px;
  }

  #nav-brand-icon {
    filter: invert(1);
    scale: 150%;
  }
}
