.image-grid-container {
  max-width: 1400px;
  width: 100%;
  margin: 40px auto;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.photo-gallery {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 600px) {
  .image-grid-container {
    width: 90%;
    margin: 10px auto;
  }

  .photo-gallery {
    flex-direction: column;
  }

  .event-section h4 {
    font-size: 16px !important;
  }
}
