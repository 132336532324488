.Footer {
  color: white;
  min-width: 100%;
  height: auto;
  padding: 20px;
  background: #212529;
}

.Footer h6 {
  color: white;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  font-weight: 500;
  font-size: 18px;
}

.Footer p {
  color: white;
  font-size: 14px;
}

#address,
#telephone {
  text-decoration: underline;
  text-decoration-style: dotted;
}

#socials a {
  color: white;
  transition: all 200ms ease-in-out;
}

#socials a:hover {
  color: rosybrown;
}

#footer-link {
  color: white;
}

/* Breakpoint: Small. Class infix: 'sm'. Dimensions: <=576px */
@media (max-width: 576px) {
  .Footer p {
    font-size: 12px;
  }
}

@media (max-width: 992px) and (orientation: landscape) {
  .Footer p {
    font-size: 12px;
  }
}
