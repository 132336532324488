.Tour .hero {
  background-image: linear-gradient(rgb(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/sixth-and-main-venue-entry.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw !important;
}

.Tour {
  background-color: #fff !important;
}

.Tour #tour-link {
  font-weight: bold;
}

@media (max-width: 690px) {
  #tour {
    height: auto !important;
    text-align: center;
  }
}

/* mobile browsers */
@media (max-width: 696px) and (orientation: portrait) {
  .Tour .hero {
    background-position-x: 50%;
    height: 65vh;
  }

  .arrow {
    color: red;
    text-transform: rotate(90) !important;
  }
}
@media (max-width: 932px) and (orientation: landscape) {
  .Tour .hero {
    height: 100vh;
  }

  .Tour #content-wrap {
    padding: 0 10px !important;
    margin: 0 auto 10px !important;
  }

  .Tour h2 {
    font-size: 22px;
  }

  .Tour p {
    font-size: 14px;
  }
}
