.Reservation {
  background: #fff !important;
}

.Reservation .hero {
  background-image: linear-gradient(rgb(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/vision3.jpg");
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* .Reservation #reservation-link {
  font-weight: bold;
} */

#regulations p,
#regulations li {
  font-size: 16px;
}

#regulations {
  height: auto;
}

.Reservation tbody > tr > th {
  font-weight: 300;
  font-size: 12px !important;
  text-align: left;
  margin-right: 0 !important;
}

/* mobile browsers */
@media (max-width: 576px) and (orientation: portrait) {
  .Reservation .hero {
    background-position-y: 40%;
    height: 65vh;
  }

  #regulations {
    height: auto;
    max-height: auto;
    width: 90%;
  }

  #regulations p,
  #regulations li {
    font-size: 14px;
  }

  #regulations li {
    text-align: left !important;
  }
}

@media (max-width: 932px) and (orientation: landscape) {
  .Reservation .hero {
    height: 100vh;
  }

  #regulations {
    height: auto;
  }

  #regulations p,
  #regulations li {
    font-size: 14px;
  }

  .Reservation #content-wrap {
    padding: 0 10px !important;
    margin: 0 auto 10px !important;
  }
}
